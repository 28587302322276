// Colours

export const textColour = "#ffffff";
export const linkColour = "#ffff99";
export const subTextColour = "#919191";
export const mainBgColour = "#222222";
export const secondaryBgColour = "#000000";

// Fonts

export const subContentFont = "'PT Sans', sans-serif;";
